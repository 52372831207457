import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'

const PageNosotros = props => {

  return (
    <Layout {...props}>
      <Seo title='Nosotros' />
      <Divider />
      
     
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='¡Bienvenidos a ANSES Blog!'
          subheader='
          

ANSES Blog es tu fuente confiable de noticias e información sobre la ayuda social en Argentina. Nos dedicamos a reunir las últimas novedades y brindarte información de manera rápida, sencilla y fácil de entender para que no tengas que lidiar con las webs oficiales de la institución, que a menudo pueden resultar difíciles de navegar.
<br><br>
Queremos ser claros desde el principio: ANSES Blog no es un sitio oficial de ANSES. Somos un blog independiente que se enfoca en proporcionar a nuestros usuarios información actualizada y accesible sobre los programas y beneficios de ayuda social en el país. Nuestra misión es simplificar la búsqueda de información y ayudarte a comprender mejor tus derechos y opciones.
<br><br>
En ANSES Blog, creemos en la importancia de la transparencia y la facilidad de acceso a la información. Sabemos que el sistema de seguridad social puede ser complejo y confuso, por lo que trabajamos arduamente para presentarte la información de manera comprensible y sin complicaciones innecesarias.
<br><br>
Nuestro equipo está comprometido en mantenerte al tanto de las últimas noticias y actualizaciones relacionadas con ANSES. Ya sea que estés interesado en jubilaciones, pensiones, asignaciones familiares, o cualquier otro programa de ayuda social, encontrarás artículos informativos y guías prácticas en nuestro blog para ayudarte a tomar decisiones informadas.
<br><br>
Entendemos que tu tiempo es valioso, y nuestro objetivo es ahorrarte tiempo y esfuerzo al proporcionarte información relevante de manera rápida y sencilla. No importa si eres un beneficiario actual o si estás investigando los beneficios disponibles, en ANSES Blog estamos aquí para ayudarte en tu viaje hacia una comprensión más profunda de la ayuda social en Argentina.
<br><br>
Gracias por confiar en ANSES Blog como tu fuente de noticias y recursos relacionados con ANSES. Estamos comprometidos en servirte de la mejor manera posible y en proporcionarte la información que necesitas para aprovechar al máximo los programas de ayuda social del país. ¡Bienvenidos a ANSES Blog!
          '
        />
      </Stack>

    </Layout>
  )
}

export default PageNosotros
